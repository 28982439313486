import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faPython, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faTerminal } from '@fortawesome/free-solid-svg-icons'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h2>Aivaras Borneika</h2>

        <h1>Full Stack Software Engineer</h1>
        <p>
          Powerfull Websites & Software engines.
          <br /> Working with many programming languages. <br />
          Main stack - React/React Native and Python(Django).
          <br />
          <br />
        </p>
        <div >
            <FontAwesomeIcon
              icon={faReact}
              style={{ color: '#fff', fontSize: 32, marginRight: 10 }}
            />
            <FontAwesomeIcon
              icon={faPython}
              style={{ color: '#fff', fontSize: 32, marginRight: 10 }}
            />
            <FontAwesomeIcon
              icon={faGithub}
              style={{ color: '#fff', fontSize: 32, marginRight: 10 }}
            />
            <FontAwesomeIcon
              icon={faTerminal}
              style={{ color: '#fff', fontSize: 32 }}
            />
          </div>
          
<br/>
        <p>
          Need an advice? <br />Drop an email
          <br />
          <a href="mailto:borneikaaivaras@gmail.com">borneikaaivaras@gmail.com</a>
        </p>
        <p>{`<---- Yes, that's me`}</p>
      </div>
    </div>
    <nav>
        <ul>
            <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
        </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Header
