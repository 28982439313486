import React, { Component } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import {navigate} from "gatsby"

import styles from "../assets/scss/layout/contactForm.module.scss";
// const [validated, setValidated] = useState(false);

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.ContactForm = React.createRef();
    this.state = {
        validated: false,
        fullName: null,
        company: null,
        service: null,
        budget: null,
        timeFrame: null,
        email: null,
        phoneNumber: null,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleSubmit = event => {
    const form = this.ContactForm.current;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.log("success");
      event.preventDefault();

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          "fullName": this.state.fullName,
          "company": this.state.company,
          "service": this.state.service,
          "budget": this.state.budget,
          "timeFrame": this.state.timeFrame,
          "email": this.state.email,
          "phoneNumber": this.state.phoneNumber,
        })
      })
        .then(response => {
            // debugger;
          console.log("====================================");
          console.log(`SUCCESS`);
          console.log("====================================");
          navigate(form.getAttribute("action"));
        })
        .catch(error => {
            // debugger;
          console.log("====================================");
          console.log(`error in submiting the form data:${error}`);
          console.log("====================================");
        });
    }

    this.setState({validated:true});
  };
  render() {
      console.log(this.state)
    return (
      <Container>
        <Row>
          <Col xs="12" className='p-0'>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
              className={`form--inline form-email ${styles.contactForm}`}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="post"
              action="/success"
              name="contact"
              ref={this.ContactForm}
              //   data-success="Thanks for your enquiry, we'll be in touch shortly."
              //   data-error="Please fill in all fields correctly."
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <span className={`d-inline-block ${styles.h4}`}>Hi, I'm</span>
              <Form.Group
                className={`${styles.mw200} d-inline-block`}
                controlId="fullName"
              >
                <Form.Control
                  required
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  className={`${styles.mw200} ${styles.contactInput}`}
                  onChange={this.handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                      Please enter your full name.
                    </Form.Control.Feedback> */}
              </Form.Group>
              <span className={`d-inline-block ${styles.h4}`}>
                and I represent
              </span>
              <Form.Group
                className={`${styles.mw200} d-inline-block`}
                controlId="company"
              >
                <Form.Control
                  required
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  className={`${styles.mw200} ${styles.contactInput}`}
                  onChange={this.handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                      Please enter a company name.
                    </Form.Control.Feedback> */}
              </Form.Group>
              <span className={`d-inline-block ${styles.h4}`}>
                I'm looking for someone to work with us on
              </span>
              <div className={`${styles.mw200} d-inline-block`}>
                <Form.Group
                  className={`${styles.mw200} d-inline-block`}
                  controlId="service"
                >
                  <Form.Control
                    as="select"
                    className={`${styles.mw200} ${styles.contactInput}`}
                    required
                    defaultValue=""
                    name="service"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Development">Web Development</option>
                    <option value="Software">Software Development</option>
                    <option value="Programming">Other Programming</option>
                  </Form.Control>
                  {/* <Form.Control.Feedback type="invalid">
                        Please choose a service.
                      </Form.Control.Feedback> */}
                </Form.Group>
              </div>
              <span className={`d-inline-block ${styles.h4}`}>
                Budget-wise, I am looking to keep around the
              </span>
              <div className={`${styles.mw200} d-inline-block`}>
                <Form.Group
                  className={`${styles.mw200} d-inline-block`}
                  controlId="budget"
                >
                  <Form.Control
                    as="select"
                    className={`${styles.mw200} ${styles.contactInput}`}
                    required
                    defaultValue=""
                    name="budget"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    <option value="0-5k">&lt; 1000</option>
                    <option value="5k-10k">1,000 - 3,000</option>
                    <option value="10k-20k">3,000 - 10,000+</option>
                  </Form.Control>
                  {/* <Form.Control.Feedback type="invalid">
                        Please choose your budget.
                      </Form.Control.Feedback> */}
                </Form.Group>
              </div>
              <span className={`d-inline-block ${styles.h4}`}>mark,</span>
              <span className={`d-inline-block ${styles.h4}`}>
                and I'm hoping to have the project complete around
              </span>
              <Form.Group
                className={`${styles.mw200} d-inline-block`}
                controlId="timeFrame"
              >
                <Form.Control
                  required
                  type="text"
                  name="timeFrame"
                  placeholder="Date / Timeframe"
                  className={`${styles.mw200} ${styles.contactInput}`}
                  onChange={this.handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                      Please enter deadline date.
                    </Form.Control.Feedback> */}
              </Form.Group>
              <span className={`d-inline-block ${styles.h4}`}>
                Contact me via
              </span>
              <Form.Group
                className={`${styles.mw200} d-inline-block`}
                controlId="email"
              >
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className={`${styles.mw200} ${styles.contactInput}`}
                  onChange={this.handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                      Please enter you email.
                    </Form.Control.Feedback> */}
              </Form.Group>
              <span className={`d-inline-block ${styles.h4}`}>or</span>
              <Form.Group
                className={`${styles.mw200} d-inline-block`}
                controlId="phoneNumber"
              >
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className={`${styles.mw200} ${styles.contactInput}`}
                  onChange={this.handleChange}
                />
                {/* <Form.Control.Feedback type="invalid">
                      Please enter a phone number.
                    </Form.Control.Feedback> */}
              </Form.Group>
              <span className={`d-inline-block ${styles.h4}`}>
                and we can discuss the project in more detail.
                <br /> <br />
                You have a vision, I have required skills for your vision to be
                succesfully fulfilled.
              </span>
              <div data-netlify-recaptcha="true"></div>
              <div>
                <Button type="submit" className={`${styles.contactButton}`}>
                  Send Enquiry
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ContactForm;
